import React from 'react';
import SEO from "../../components/seo"
import Layout from "../../components/layout"

export default function ThankYou(){

    return(
       <Layout>
          <SEO 
              title="Thank you for the email"
              description="We’ll respond to your enquiry via email within 24 hours."
            />
            <br /><br />
            <br />
          <div className="container center">
            <h1 className="center">Thank you for the message.</h1>
            <p  className="center">We’ll respond to your enquiry via email within 24 hours.</p>
            <br /><br />
            <br /><br />
            <br /><br /><br />
          </div>
      </Layout>
    );
}